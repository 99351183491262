body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f0f0f0;
}
.stats-card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    max-width: 800px;
    margin: 0 auto;
}
h1, h2 {
    color: #333;
    margin-top: 0;
}
.proposer-section {
    margin-bottom: 20px;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}
th {
    background-color: #f5f5f5;
}
.count {
    font-weight: bold;
    color: #666;
}
.bolt {
    background-color: #e6f3ff;
}
.interstate {
    background-color: #fff0e6;
}
